import FacebookIcon from "@material-ui/icons/Facebook";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { useTranslation } from "i18n";
import { getClientsideHref } from "utils";
import routes from "routes";

const FacebookButton = () => {
  const { t } = useTranslation();

  const redirectUri = encodeURIComponent(`${getClientsideHref().host}${routes.CALLBACK_FACEBOOK}`);

  const onClick = () => {
    const link = `https://www.facebook.com/v10.0/dialog/oauth?response_type=token&client_id=${process.env.NEXT_PUBLIC_FB_ID}&state=asd&redirect_uri=${redirectUri}`;
    window.location.href = link;
  };

  return (
    <Box width="100%">
      <Button
        onClick={onClick}
        variant="contained"
        startIcon={<FacebookIcon />}
      >
        {t("FACEBOOK_BUTTON")}
      </Button>
    </Box>
  );
};

export default FacebookButton;
