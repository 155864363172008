import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import FacebookButton from "components/Auth/FacebookButton";
import GoogleButton from "components/Auth/GoogleButton";

import withNoAuthentication from "hocs/withNoAuthentication";
import withLandingLayout from "hocs/withLandingLayout";

import { useTranslation } from "i18n";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  continueButtons: {
    maxWidth: 250,
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  image1: {
    maxHeight: 400,
    objectFit: "contain",
    position: "absolute",
    left: "-10%",
    [theme.breakpoints.down("xs")]: {
      left: 0,
    },
  },
  image2: {
    maxHeight: 500,
    objectFit: "contain",
    position: "absolute",
    right: 0,
    top: -200,
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Container
      maxWidth="lg"
      className={classes.container}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography paragraph variant="h3" className="bold">
            {t("LANDING_TITLE")}
          </Typography>
          <Typography paragraph>
            {t("LANDING_SUBTITLE")}
          </Typography>
          <Typography paragraph />
          <Box className={classes.continueButtons}>
            <GoogleButton />
            <FacebookButton />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box position="relative">
            <Hidden xsDown>
              <Fade in style={{ transitionDelay: 100 }}>
                <img src="/assets/asset-landing-1.png" alt="logo" className={classes.image1} />
              </Fade>
            </Hidden>
            <Hidden smDown>
              <Slide in direction="right">
                <Fade in style={{ transitionDelay: 250 }}>
                  <img src="/assets/asset-landing-2.png" alt="logo" className={classes.image2} />
                </Fade>
              </Slide>
            </Hidden>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withLandingLayout(withNoAuthentication(Home));
