import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSession } from "store/Session";

const withNoAuthentication = (Component) => {
  const ProtectedRoute = (props) => {
    const { isLoggedIn, data } = useSession();
    const router = useRouter();

    useEffect(() => {
      if (isLoggedIn) {
        if (data && !data.user.onboardingComplete) {
          router.replace({
            pathname: "/onboarding",
          });
        } else {
          // const { redirect } = router.query;
          // router.replace(redirect || "/home");
          router.replace("/home");
        }
      }
    }, [isLoggedIn]);
  
    if (!isLoggedIn) return <Component {...props} />;
    return null;
  };
  return ProtectedRoute;
};

export default withNoAuthentication;
