import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
// import { getBrowserLanguageNumber } from "utils";
import es from "./locales/es.json";

const mapCodeToDictionary = {
  1: es,
};
const I18nContext = createContext({
  t: () => {},
  language: "",
  setLanguage: () => {},
});

export const useTranslation = () => useContext(I18nContext);

export const I18nProvider = ({ children }) => {
  // const defaultLanguage = getBrowserLanguageNumber();
  const defaultLanguage = 1; // espaniol
  const [language, setLanguage] = useState(defaultLanguage);
  const t = useCallback((key) => mapCodeToDictionary[language][key], [language]);
  const context = useMemo(() => ({ language, setLanguage, t }), [language, t]);

  useEffect(() => {
  }, [language]);

  return (
    <I18nContext.Provider value={context}>
      {children}
    </I18nContext.Provider>
  );
};

export const withI18n = (Component) => (props) => (
  <I18nProvider>
    <Component {...props} />
  </I18nProvider>
);
