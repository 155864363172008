import Button from "@material-ui/core/Button";
import { useTranslation } from "i18n";

import { getClientsideHref } from "utils";
import routes from "routes";
import GoogleIcon from "../Icons/Google";

const GoogleButton = () => {
  const { t } = useTranslation();
  
  const { host } = getClientsideHref();
  const redirectUri = encodeURIComponent(`${getClientsideHref().host}${routes.CALLBACK_GOOGLE}`);

  const onClick = () => {
    const link = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${redirectUri}&response_type=permission%20id_token&scope=email%20profile%20openid&openid.realm=&client_id=${process.env.NEXT_PUBLIC_GOOGLE_ID}&ss_domain=${encodeURIComponent(host)}&prompt=&fetch_basic_profile=true&gsiwebsdk=2`;
    window.location.href = link;
  };

  return (
    <Button
      onClick={onClick}
      startIcon={<GoogleIcon />}
      variant="contained"
    >
      {t("GOOGLE_BUTTON")}
    </Button>
  );
};

export default GoogleButton;
