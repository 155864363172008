import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useRouter } from "next/router";
import Button from "@material-ui/core/Button";

import { Hidden } from "@material-ui/core";
import LogoPositiveFilled from "components/Logos/LogoPositiveFilled";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    display: "flex",
    minHeight: "calc(100vh - 70px)",
    flexDirection: "column",
  },
  header: {
    width: "100%",
    minHeight: 70,
    display: "flex",
    alignItems: "center",
  },
  footer: {
    width: "100%",
    minHeight: 50,
    bottom: 0,
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  },
  footerText: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  headerContainer: {
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    alignItems: "center",
    display: "flex",
  },
  container: {
    background: "url(/assets/trama.png)",
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
    flexDirection: "column",
    height: "100%",
    display: "flex",
  },
}));

const Desktop = ({ children }) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box className={classes.container}>
      <Paper className={classes.header} elevation={20}>
        <Container maxWidth="lg" className={classes.headerContainer}>
          <Box display="flex" alignItems="center">
            <LogoPositiveFilled />
          </Box>
        </Container>
      </Paper>
      <Box className={classes.contentContainer}>
        {children}
      </Box>
      <Paper className={classes.footer} elevation={20}>
        <Box className={classes.headerContainer}>
          <Button
            size="small"
            className={classes.footerText}
            onClick={() => router.push("/tc")}
          >
            <Hidden xsDown>
              Términos y condiciones
            </Hidden>
            <Hidden smUp>
              T&C
            </Hidden>
          </Button>
          -
          <Button
            size="small"
            className={classes.footerText}
            onClick={() => router.push("/rules")}
          >
            Reglas
          </Button>
          -
          <Button
            size="small"
            className={classes.footerText}
            onClick={() => router.push("/privacy")}
          >
            <Hidden xsDown>
              Políticas de
            </Hidden>
            {" "}
            Privacidad
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Desktop;
